import(/* webpackMode: "eager" */ "/home/circleci/project/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/circleci/project/packages/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/circleci/project/packages/frontend/src/components/snippets/DatadogSnippet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/circleci/project/packages/frontend/src/components/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EnvironmentProvider"] */ "/home/circleci/project/packages/frontend/src/providers/Environment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoggingProvider"] */ "/home/circleci/project/packages/frontend/src/providers/Logging.tsx");
