'use client';

import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { isRemoteEnvironment } from '../../lib/environment';
import useEnvironment from '../../providers/Environment';

function DatadogSnippet() {
  const config = useEnvironment();

  useEffect(() => {
    if (!config || !isRemoteEnvironment(config)) return;
    datadogRum.init({
      applicationId: config.datadog.appId!,
      clientToken: config.datadog.clientToken!,
      site: 'us3.datadoghq.com',
      service: config.datadog.service,
      env: config.natoma.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: config.datadog.version, // should be short commit hash
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogLogs.init({
      clientToken: config.datadog.clientToken!,
      site: 'us3.datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100
    });

    return () => {};
  }, [config]);

  return <React.Fragment />;
}

export default DatadogSnippet;
