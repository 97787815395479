export const interFontFamily = ['Inter', '-apple-system', 'sans-serif'].join(
  ','
);
export const spaceGroteskFontFamily = [
  'Space Grotesk',
  '-apple-system',
  'sans-serif'
].join(',');

export const fontStyles = {
  displayLargeNormal: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 32,
    fontWeight: '400',
    lineHeight: '44px'
  },
  displayLargeMedium: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 32,
    fontWeight: '500',
    lineHeight: '44px'
  },
  displayLargeSemibold: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 32,
    fontWeight: '600',
    lineHeight: '44px'
  },
  displayLargeBold: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 32,
    fontWeight: '700',
    lineHeight: '44px'
  },
  displayRegularNormal: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 24,
    fontWeight: '400',
    lineHeight: '36px'
  },
  displayRegularMedium: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 24,
    fontWeight: '500',
    lineHeight: '36px'
  },
  displayRegularSemibold: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 24,
    fontWeight: '600',
    lineHeight: '36px'
  },
  displayRegularBold: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 24,
    fontWeight: '700',
    lineHeight: '36px'
  },
  displaySmallNormal: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 20,
    fontWeight: '400',
    lineHeight: '32px'
  },
  displaySmallMedium: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 20,
    fontWeight: '500',
    lineHeight: '32px'
  },
  displaySmallSemibold: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 20,
    fontWeight: '600',
    lineHeight: '32px'
  },
  displaySmallBold: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 20,
    fontWeight: '700',
    lineHeight: '32px'
  },
  displayExtraSmallMedium: {
    fontFamily: spaceGroteskFontFamily,
    fontSize: 18,
    fontWeight: '500',
    lineHeight: '28px'
  },
  textLargeNormal: {
    fontFamily: interFontFamily,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: '24px'
  },
  textLargeMedium: {
    fontFamily: interFontFamily,
    fontSize: 15,
    fontWeight: '500',
    lineHeight: '24px'
  },
  textLargeSemibold: {
    fontFamily: interFontFamily,
    fontSize: 15,
    fontWeight: '600',
    lineHeight: '24px'
  },
  textLargeBold: {
    fontFamily: interFontFamily,
    fontSize: 15,
    fontWeight: '700',
    lineHeight: '24px'
  },
  textRegularNormal: {
    fontFamily: interFontFamily,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: '20px'
  },
  textRegularMedium: {
    fontFamily: interFontFamily,
    fontSize: 13,
    fontWeight: '500',
    lineHeight: '20px'
  },
  textRegularSemibold: {
    fontFamily: interFontFamily,
    fontSize: 13,
    fontWeight: '600',
    lineHeight: '20px'
  },
  textRegularBold: {
    fontFamily: interFontFamily,
    fontSize: 13,
    fontWeight: '700',
    lineHeight: '20px'
  },
  textMiniNormal: {
    fontFamily: interFontFamily,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '20px'
  },
  textMiniMedium: {
    fontFamily: interFontFamily,
    fontSize: 12,
    fontWeight: '500',
    lineHeight: '20px'
  },
  textMiniSemibold: {
    fontFamily: interFontFamily,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: '20px'
  },
  textMiniBold: {
    fontFamily: interFontFamily,
    fontSize: 12,
    fontWeight: '700',
    lineHeight: '20px'
  },
  textMicroNormal: {
    fontFamily: interFontFamily,
    fontSize: 11,
    fontWeight: '400',
    lineHeight: '16px'
  },
  textMicroMedium: {
    fontFamily: interFontFamily,
    fontSize: 11,
    fontWeight: '500',
    lineHeight: '16px'
  },
  textMicroSemibold: {
    fontFamily: interFontFamily,
    fontSize: 11,
    fontWeight: '600',
    lineHeight: '16px'
  },
  textMicroBold: {
    fontFamily: interFontFamily,
    fontSize: 9,
    fontWeight: '400',
    lineHeight: '16px'
  },
  textNanoNormal: {
    fontFamily: interFontFamily,
    fontSize: 8,
    fontWeight: '400',
    lineHeight: '12px'
  }
};
